/**********Sticky header ************ */
document.addEventListener("DOMContentLoaded", onDOMReady);
function onDOMReady() {
    let menuHeader = document.querySelector('header');
    let mainContainer = document.querySelector('main');
    if(Boolean(menuHeader, mainContainer)) {
        window.addEventListener('scroll', onWindowScroll);
        function onWindowScroll() {
            if(window.document.scrollingElement.scrollTop > 120){
                menuHeader.classList.add("sticky");
                mainContainer.classList.add("main__margin-top");
            }
            else {
                menuHeader.classList.remove("sticky")
                mainContainer.classList.remove("main__margin-top");
            }
        }
    }
}

/********** Button back  ************ */
const pushHistori = () => {
    if(document.querySelector('.push-history')) {
        document.querySelector('.push-history').addEventListener("click", (e) => {
            e.preventDefault();
            window.history.back();
        })
    }
}
pushHistori();

/********** Not click in lang menu current item ************ */
if(document.querySelector('.current-language-menu-item')) {
    document.querySelector('.current-language-menu-item > a').addEventListener('click', (e) => {
        e.preventDefault();
    });
}

/********** show/hide category in News page and Gallery page ************ */
if(document.querySelector('.category-list-btn') && document.querySelectorAll('.category-filter').length > 0) {
    const openBtn = document.querySelector('.category-list-btn');

    const handleButtonsShow = () => {
        document.querySelectorAll('.category-filter').forEach((item) => {
            item.classList.toggle('show-cat');
        });
        openBtn.classList.toggle('show-cat');
    }

    openBtn.addEventListener('click', handleButtonsShow);
}

/*********** modal ************/
const modal = () => {
    let eventBtn = ".modal-form";
    let modalId = "modal-contact";
    if(!document.querySelectorAll(eventBtn).length || !document.getElementById(modalId)) {
        return;
    }
    let getModal = document.getElementById(modalId);
    let getBtn = document.querySelectorAll(eventBtn);
    let closeBtn = getModal.querySelector(".close-modal");
    let modalContain = getModal.querySelector(".container");

    const openModal = () => {
        getModal.classList.add("show");
        document.querySelector("body").classList.add("stop");
    };
    const closeModal = () => {
        getModal.classList.remove("show");
        document.querySelector("body").classList.remove("stop");
    };
    const showNoti = () => {
        modalContain.classList.add("show");
        setTimeout(hideNoti, 1500);
    };
    const hideNoti = () => {
        closeModal();
        setTimeout(modalContain.classList.remove("show"), 1000);
    };

    getBtn.forEach((item) => item.addEventListener("click", openModal));
    getModal.addEventListener("click", (e) => {
        e.preventDefault();
        modalContain.contains(e.target) ? "" : closeModal();
    });

    closeBtn.addEventListener("click", closeModal);
    document.addEventListener("keydown", (e) => {
        e.key === "Escape" ? closeModal() : "";
    });

    document.addEventListener(
        "wpcf7mailsent",
        (event) => {
            if (event.detail.contactFormId == id) {
                showNoti();
            }
        },
        false
    );
};
modal();

/********** back-to-top-btn ************ */
    function backToTop(){
        if(document.querySelectorAll('#back-to-top-btn').length > 0){
            document.querySelector('#back-to-top-btn').addEventListener('click', function(){
                document.querySelector('html,body').animate({
                        scrollTop: 0
                    },
                    400
                );
            });

            window.addEventListener('scroll', function(){
                if(window.document.scrollingElement.scrollTop>= document.querySelector('#page-body').scrollTop) {
                    document.querySelector('#back-to-top-btn').classList.add('show-this');
                }
                else {
                    document.querySelector('#back-to-top-btn').classList.remove('show-this');
                }
            })
        }
    }
    backToTop();

/* **** Burger **** */
const burgerHandler = () => {
    if(!document.querySelector('.burger-menu')) {
        return;
    }
    const burgerBtn = document.querySelector('.burger-menu');
    const menu = document.querySelector('.main-menu');
    const body = document.querySelector('body');
    burgerBtn.addEventListener('click', handleMenu);
    function handleMenu() {
        burgerBtn.classList.toggle('show');
        menu.classList.toggle('show');
        body.classList.toggle('show');
    }
}
burgerHandler();

/* **** Fax **** */
const faxHandlerClick = () => {
    if(!document.querySelector('.fax-link')) {
        return;
    }
    const faxLink = document.querySelector('.fax-link');
    faxLink.addEventListener('click', function(event) {
        event.preventDefault();
        try {
            window.open(this.href);
        } catch (error) {
            alert('You do not have fax software installed!');
            console.log(error);
        }
    });
}
faxHandlerClick();

/* **** Menu drop down **** */
const menuDropDown = () => {
    if(document.querySelectorAll('.main-menu__list__item.menu-item-has-children').length > 0) {
        const mainMenus = document.querySelectorAll('.main-menu__list__item.menu-item-has-children');
        const showEvents = ['mouseenter', 'focus'];
        const hideEvents = ['mouseleave', 'blur'];
        mainMenus.forEach((item) => {
            showEvents.forEach((event) => {
                item.addEventListener(event, () => {
                    item.classList.add('show');
                });
            });
            hideEvents.forEach((event) => {
                item.addEventListener(event, () => {
                    item.classList.remove('show');
                });
            });
        });
    }

    const showEvents = ["mouseenter", "focus"];
    const hideEvents = ["mouseleave", "blur"];

    if(document.querySelectorAll('.footer-menu__list__item.menu-item-has-children').length > 0) {
        function footerMenu(event) {
            const footerMenuList = document.querySelectorAll('.footer-menu__list__item.menu-item-has-children');
            footerMenuList.forEach((item) => {
                item.addEventListener(event, () => {
                    item.classList.toggle('show');
                });
            });
        }
        showEvents.forEach((event) => footerMenu(event));
        hideEvents.forEach((event) => footerMenu(event));
    }
}
menuDropDown();

/* **** Toast **** */
import {Toast} from 'bootstrap';

const toastContainer = document.querySelector('.toast');
const toastBody = toastContainer.querySelector('.toast-body');
const toastInit = new Toast(toastContainer, {
    animation: true,
    autohide: true,
    delay: 3000
  });
  const setMessage = (event) => {
    const message = event.detail.apiResponse.message;
    toastBody.innerHTML = '';
    toastBody.innerHTML += '<span>' + message + '</span>';
  }

/* **** Error **** */
document.addEventListener( 'wpcf7invalid', function( event ) {
    toastContainer.classList.remove('success');
    toastContainer.classList.add('error');
    setMessage(event);
    toastInit.show();
  }, false );

/* **** Success **** */
document.addEventListener( 'wpcf7mailsent', function( event ) {
    toastContainer.classList.remove('error');
    toastContainer.classList.add('success');
    setMessage(event);
    toastInit.show();
  }, false );

/* **** File upload **** */
  document.addEventListener('DOMContentLoaded', function() {
    if(!document.getElementById('file-upload')) {
        return;
    }
    const fileUpload = document.getElementById('file-upload');
    const fileNameInner = document.querySelector('.field-info-inner');
    const form = document.querySelector('form');
    form.setAttribute('enctype','multipart/form-data');

    fileUpload.addEventListener('change', (e) => {
        if(e.target.files.length > 1) {
            fileNameInner.classList.add('inner');
            fileNameInner.innerHTML = `<span>Files uploaded: ${e.target.files.length}</span>`;
        } else {
            fileNameInner.classList.add('inner');
            fileNameInner.innerHTML = `<span>${e.target.files[0].name}</span>`
        }
    });
    document.addEventListener( 'wpcf7mailsent', function( event ) {
        fileNameInner.classList.remove('inner');
        fileNameInner.innerHTML = '';
    });
  });